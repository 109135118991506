@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Orpheus Pro';
    font-style: normal;
    font-weight: 400;
    src: local('Orpheus Pro'), url("./fonts/Orpheus Pro.woff2") format('woff2'), url("./fonts/Orpheus Pro.woff") format('woff');
  }

  @font-face {
    font-family: 'Garamond Pro';
    font-style: normal;
    font-weight: 400;
    src: local('Garamond Pro'), url("./fonts/AGaramondPro-Regular.woff2") format('woff2'), url("./fonts/AGaramondPro-Regular.woff") format('woff');
  }

  @font-face {
    font-family: 'Garamond Pro';
    font-style: italic;
    font-weight: 400;
    src: local('Garamond Pro'), url("./fonts/AGaramondPro-Italic.woff2") format('woff2'), url("./fonts/AGaramondPro-Italic.woff") format('woff');
  }

  @font-face {
    font-family: 'Garamond Pro';
    font-style: oblique;
    font-weight: bold;
    src: local('Garamond Pro'), url("./fonts/AGaramondPro-Bold.woff2") format('woff2'), url("./fonts/AGaramondPro-Bold.woff") format('woff');
  }

  p {
    font-size: 1.5rem;
    font-weight: 400;
  }

  h1 {
    font-family: 'Orpheus Pro', serif;
  }

  h2 {
    font-family: 'Orpheus Pro', serif;
  }

  h3 {
    font-family: "Garamond Pro", serif;
  }
}
